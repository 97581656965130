// google analytics
import ReactGA from "react-ga4";

// common
import NotFound from './components/NotFound';
import Layout from './components/Layout';

// RUTIFIKADOR
import Persona from './components/Persona';
import Search from './components/Search';
//

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

ReactGA.initialize("G-TR1JRY5CT3");

const router = createBrowserRouter([
    {   
        path: "not-found",
        element: <NotFound />,
        },
    {   
        path: "",
        element: <Layout />,
        children: [
            {   
                path: "",
                element: <Search />,
            },
            {   
                path: ":rut/:name_slug",
                element: <Persona />,
            },
        ]
    },
    {
        path: "*",
        element: <NotFound />
        }
]);

const App = () => {
    return (
        <RouterProvider router={router} />
        );
}

export default App;
